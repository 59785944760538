import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from 'react-share'
import { rhythm } from "../utils/typography";

function Share({ articleUrl, articleTitle }) {
  return (
    <StaticQuery
      query={socialQuery}
      render={data => {
        const { twitter } = data.site.siteMetadata.social.twitter
        return (
          <div
            style={{
              display: `flex`,
              width: `120px`,
              justifyContent: `space-between`,
              marginBottom: rhythm(1 / 3),
            }}
          >
            <span>Share:</span>
            <FacebookShareButton url={articleUrl}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton title={articleTitle} url={articleUrl} via={twitter}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        )
      }}
    />
  );
}

const socialQuery = graphql`
  query SocialQuery {
    site {
      siteMetadata {
        social {
          twitter
        }
      }
    }
  }
`

export default Share;
